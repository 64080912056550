<template>
  <div>
    <div class="wrapper">
      <AppHeader :menu="headerMenu" />
      <div class="content sp-t-4-4 td-sp-t-3">
        <slot />
      </div>
      <AppFooter :menu="footerMenu"/>
    </div>
  </div>
</template>

<script setup>
const runtimeConfig = useRuntimeConfig()

const response = await useFetch(toRaw(runtimeConfig).public.strapiUrl + '/api/menus?nested&populate=*')
const menus = toRaw(response.data.value.data).map((item) => {
  return {
    id: item.id,
    ...item.attributes
  }
})

let headerMenu, footerMenu

if (response.data?.value) {
  headerMenu = menus.find(menu => menu.slug === 'header')
  footerMenu = menus.find(menu => menu.slug === 'footer')
}
const countScrollbar = () => {
  if (process.client) {
    const scrollbarWidth = window.innerWidth - document.body.clientWidth
    document.documentElement.style.setProperty('--scrollbarWidth', `${scrollbarWidth}px`)
    if(window.innerWidth - document.body.clientWidth === 0) {
      setTimeout(() => {
        countScrollbar()
      },100)
    }
  }
}
countScrollbar()

</script>

<style lang="stylus">
.wrapper
  max-width 100%
  margin auto

//.content
//   padding-top 96px
</style>
