<template>
  <header class="mobile-header td-d-flex d-none td-sp-h-1 td-sp-t-0-4">
    <NuxtLink to="/" @click="goToLink" class="logo">
      <span v-html="logo"></span>
    </NuxtLink>

    <nav class="mobile-navigation">
      <div @click="openMenu" class="burger">
        <div :class="[ 'burger-line', 'l-t', ...burgerLineClassList ]"></div>
      </div>
    </nav>
  </header>
  <div :class="mainDropdownClassList">
    <div v-for="(item, idx) of menus" :key="item.id" class="mobile-header-dropdown-item sp-h-1">
      <div class="mobile-header-dropdown-item-title sp-b-0-4">
        <div v-if="item.children.length" @click="openChildren(idx)" class="font-h-s">{{ item.title }}</div>
        <NuxtLink v-else :to="item.url" :target="item.target" @click="goToLink" class="font-h-s">{{ item.title }}</NuxtLink>
        <span
          v-if="item.children.length"
          @click="openChildren(idx)"
          v-html="icons.chevronBlack"
          :class="[ activeSubmenus.includes(idx) ? 'chevron-active' : 'chevron']"
        ></span>
      </div>
      <div
        v-if="item.children.length"
        class="mobile-header-dropdown-item-subitem-container"
        :style="{ 'max-height': activeSubmenusHeights[idx] }"
        :class="[ activeSubmenus.includes(idx) ? 'mobile-header-dropdown-item-subitem-container-active' : '' ]"
      >
        <NuxtLink
          v-for="subitem of item.children" :key="subitem.id"
          :to="item.url + subitem.url" :target="subitem.target" @click="goToLink" class="mobile-header-dropdown-item-subitem sp-b-1 font-t-m"
        >
          {{ subitem.title }}
        </NuxtLink>
      </div>
    </div>
    <div class="mobile-header__minibanner sp-v-1">
      <div class="sp-h-1">
        <p class="mobile-header__minibanner__header font-h-s sp-b-0-3">
          {{ miniBannerContent.title }} <span class="arrow-right sp-l-0-3" v-html="icons.arrowRight"></span>
        </p>
        <p class="mobile-header__minibanner__description font-t-s">{{ miniBannerContent.description }}</p>
      </div>
    </div>
  </div>
</template>

<script setup>
import { menusQuery } from '~/graphql/menuMinibanners'

import { logo } from '../../assets/rtLogo'
import icons from '~/assets/icons';

const props = defineProps({
  menu: Object
})

const config = useRuntimeConfig()

const goToLink = () => {
  if (isMenuOpened.value) {
    openMenu()
  }
  activeSubmenus.value = []
  for (const key in activeSubmenusHeights.value) {
    activeSubmenusHeights.value[key] = '0px'
  }
  isMenuOpened.value = false
}

const isMenuOpened = ref(false)
const mainDropdownClassList = ref([ 'mobile-header-dropdown' ])
const burgerLineClassList = ref([ 'burger-line' ])
const activeSubmenus = ref([])
const activeSubmenusHeights = ref({})
const miniBannerContent = ref({})

const menus = toRaw(props.menu.items.data).map(item => {
  return {
    id: item.id,
    url: item.attributes.url,
    title: item.attributes.title,
    menu_banner: item.attributes.menu_banner,
    target: item.attributes.target,
    children: item.attributes.children.data.map(sd => {
      const titleWithDescription = sd.attributes.title.split('/')
      return {
        id: sd.id,
        title: titleWithDescription[0],
        desc: titleWithDescription[1] ?? '',
        url: sd.attributes.url,
        target: sd.attributes.target
      }
    })
  }
})

const openMenu = () => {
  isMenuOpened.value = !isMenuOpened.value

  if (isMenuOpened.value) {
    document.body.style.overflow = 'hidden'
    document.body.style.touchAction = 'none'
    burgerLineClassList.value.push('burger-line--active')
    mainDropdownClassList.value.push('mobile-header-dropdown-active')
  } else {
    document.body.style.overflow = 'auto'
    document.body.style.touchAction = 'auto'
    burgerLineClassList.value = [ 'burger-line']
    mainDropdownClassList.value = [ 'mobile-header-dropdown' ]
    activeSubmenus.value = []
    for (const key in activeSubmenusHeights.value) {
      activeSubmenusHeights.value[key] = '0px'
    }
  }
}

const openChildren = (idx) => {
  if (!activeSubmenus.value.includes(idx)) {
    activeSubmenus.value.push(idx)
    activeSubmenusHeights.value[idx] = menus[idx].children.length * 44 + 'px'
  } else {
    activeSubmenus.value.splice(activeSubmenus.value.indexOf(idx), 1)
    activeSubmenusHeights.value[idx] = '0px'
  }
}

const getMiniBanners = async () => {
  const miniBanners = menus.filter(item => item.menu_banner).map(menu => {
    return menu.menu_banner
  })
  const res = await useAsyncQuery(menusQuery, { slug: miniBanners })
  if (res.data.value) {
    miniBannerContent.value = res.data.value.minibanners.data[0]?.attributes
  }
}

await getMiniBanners()

</script>

<style lang="stylus">
.mobile-header
  position fixed
  z-index 999
  height 60px
  top 0
  left 0
  right 0
  background-color #fff
  border-bottom 2px solid #EEEFF0
  justify-content space-between

  .logo
    span
      display block
      width 76px
      height 28px
      svg
        width 76px
        height 28px
      @media (max-width: tablet-upper-limit)
        width auto
        height auto
        svg
          width 126px
          height auto
  
  &-dropdown
    position fixed
    top 60px
    width 100%
    height 0
    max-height 0
    transition all .3s
    background-color #fff
    z-index 100
    overflow hidden

    &-active
      transition all .3s
      height calc(100vh - 60px)
      max-height calc(100vh - 60px)
      padding-top 20px
      overflow auto
      &::-webkit-scrollbar
        display none

    &-item
      color main-color
      a
        color main-color
      &-title
        display flex
        align-items center
        justify-content space-between
        height 24px
        box-sizing content-box
        div
          width 100%

        .chevron
          height 24px
          transition all .3s

          &-active
            height 24px
            transition all .3s
            transform rotateZ(-180deg)

      
      &-subitem
        display block
        color main-color07!important
        font-size 14px
        line-height 16px
      
        &-container
          max-height 0
          overflow hidden
          transition max-height .3s

          &-active
            // height 100%
            transition max-height .3s

  &__minibanner
    border-top 1px solid main-color01

    &__header
      display flex
      justify-content flex-start
      align-items center
      svg
        path
          fill main-color
      @media (max-width: mobile-upper-limit)
        justify-content space-between

    &__description
      color main-color05

.mobile-navigation

  .burger
    width 24px
    height 24px
    padding 11px 2px

    &-line
      display block
      position relative
      left 2px
      right 2px
      height 2px
      background-color main-color
      transition all .3s
      &:before,
      &:after
        position absolute
        content ''
        right 0
        left 0
        height 2px
        background-color main-color
      &:before
        top -6px
      &:after
        bottom -6px
      &--active
        transform rotate(135deg) scale(.9)
        &:before
          top 0
        &:after
          bottom 0
          transform rotate(90deg)

.arrow-right
  display flex
  justify-content center
  align-items center
</style>
