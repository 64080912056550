import gql from 'graphql-tag'

export const menusQuery = gql`
query menus($slug: [String]!) {
  minibanners(filters: { slug: { in: $slug } }) {
    data {
      id
      attributes {
        slug
        title
        description
        link
        image {
          data {
            attributes {
              url
            }
          }
        }
      }
    }
  }
}
`