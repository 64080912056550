<template>
  <header
    @mouseleave="hideMenu"
    class="header td-d-none sp-t-1-1"
  >
    <div class="rt-container">
      <div class="inner rt-col">
        <NuxtLink to="/" class="logo">
          <span v-html="logo"></span>
        </NuxtLink>
        <nav class="navigation">
          <NuxtLink
            v-for="(item, index) of menus" :key="menus.value"
            :to="isTablet && item.children.length ? '' : item.url"
            @mouseover="openSubmenuOnHover(item, index, item.id)"
            @click="openSubmenuByClick(item, index, item.id)"
            ref="headerlink"
            class="navigation-item font-h-l sp-l-1"
            :target="item.target"
          >
            {{item.title}}
            <span v-if="item.children.length" v-html="icons.chevron" class="sp-l-0-1"></span>
          </NuxtLink>
        </nav>
        <div
          v-show="showDropdownMenu"
          class="dropdown-content sp-t-1 sp-h-1"
          ref="dropdownmenu"
        >
          <div v-for="submenu of dropdownMenuContentPrebuilt" class="dropdown-content-containers">
            <div :key="index" v-show="submenu.id === currentMenuSelected">
              <div class="dropdown-content-container sp-b-0-1" :class="{'dropdown-content-container--block' : submenu.id === 'more'}">
                <div
                  v-for="link of submenu.data" :key="link.id"
                  class="dropdown-content-container-item font-t-m sp-b-0-4"
                >
                  <NuxtLink :to="hoveredUrl + link.url" :target="link.target">
                    <p class="dropdown-content-container-item-header font-t-m">{{ link.title }}</p>
                    <p class="dropdown-content-container-item-desc font-t-xs">{{ link.desc }}</p>
                  </NuxtLink>
                </div>
              </div>
              <div v-if="submenu.banner" class="header-banner sp-v-1 d-flex">
                <img :src="submenu.banner.image" alt="" />
                <div class="header-banner__content sp-l-1">
                  <p class="header-banner__content__header font-h-l sp-b-0-3">{{ submenu.banner.title }}</p>
                  <p class="header-banner__content__description font-t-s sp-b-0-4">{{ submenu.banner.description }}</p>
                  <a class="header-banner__content__link font-t-s" :href="submenu.banner.link">Подробнее</a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </header>
</template>

<script setup>
import { menusQuery } from '~/graphql/menuMinibanners'
import { logo } from '../../assets/rtLogo'
import icons from '../../assets/icons.ts'

const inst = getCurrentInstance()

onBeforeMount(async () => {
  isTablet.value = window.innerWidth < 1025
  await getMiniBanners()
  setMenuType()

  window.addEventListener('resize', () => {
    setMenuType()
    isTablet.value = window.innerWidth < 1025
  })
})

const props = defineProps({
  menu: Object
})

const config = useRuntimeConfig()

const isTablet = ref(false)
let menus = ref([])
let showDropdownMenu = ref(false)
// собранный заранее контент для выпадашек, чтобы не перерисовывать каждый раз и не подгружать картинки на каждый ховер
let dropdownMenuContentPrebuilt = ref([])
const headerlink = ref([]) // ref in template
const dropdownmenu = ref(null) // ref in template
const hoveredUrl = ref('')
const currentMenuSelected = ref()

const rawMenus = toRaw(props.menu.items.data).map(item => {
  return {
    id: item.id,
    url: item.attributes.url,
    title: item.attributes.title,
    menu_banner: item.attributes.menu_banner,
    target: item.attributes.target ?? '_self',
    children: item.attributes.children.data.map(sd => {
      const titleWithDescription = sd.attributes.title.split('/')
      return {
        id: sd.id,
        title: titleWithDescription[0],
        desc: titleWithDescription[1] ?? '',
        url: sd.attributes.url,
        target: sd.attributes.target
      }
    })
  }
})

const getMiniBanners = async () => {
  const miniBanners = rawMenus.filter(item => item.menu_banner).map(menu => {
    return menu.menu_banner
  })
  const res = await useAsyncQuery(menusQuery, { slug: miniBanners })
  if (res.data.value) {
    const banners = res.data.value.minibanners.data.map(item => {
      return {
        ...item.attributes,
        image: config.public.strapiUrl + item.attributes.image?.data.attributes.url || ''
      }
    })
    rawMenus.forEach(item => {
      if (banners.find(banner => banner.slug === item.menu_banner)) {
        item.bannerContent = banners.find(banner => banner.slug === item.menu_banner)
      }
    })
  }
}

const prebuildDropdowns = () => {
  dropdownMenuContentPrebuilt.value = menus.value
    .filter(item => item.children.length)
    .map(menu => {
      return {
        id: menu.id,
        data: menu.children,
        banner: menu?.bannerContent
      }
    })
}

const renderSubmenu = (index) => {
  const xPos = toRaw(headerlink.value[index]).$el.getBoundingClientRect().x
  dropdownmenu.value.style.left = xPos - dropdownmenu.value.getBoundingClientRect().width / 4 + 'px'
  const overScreenRight = 
    dropdownmenu.value.getBoundingClientRect().x + dropdownmenu.value.getBoundingClientRect().width > window.innerWidth
  const overScreenLeft = dropdownmenu.value.getBoundingClientRect().x < 0
  if (overScreenRight) {
    // 70 = 30(container padding-left) + 40(design demand)
    dropdownmenu.value.style.left = (window.innerWidth -  dropdownmenu.value.getBoundingClientRect().width - 70) + 'px'
  }
  if (overScreenLeft) {
    dropdownmenu.value.style.left = '0px'
  }
}

const openSubmenuOnHover = (item, index, menuId) => {
  if (!isTablet.value && item.children.length) {
    currentMenuSelected.value = menuId
    hoveredUrl.value = item.url
    showDropdownMenu.value = true
    nextTick(() => {
      renderSubmenu(index)
      document.body.classList.add('darkened')
    })
  } else {
    showDropdownMenu.value = false
  }
}

const openSubmenuByClick = (item, index, menuId) => {
  if (isTablet.value && item.children.length) {
    currentMenuSelected.value = menuId
    hoveredUrl.value = item.url
    showDropdownMenu.value = !showDropdownMenu.value
    if (showDropdownMenu.value) {
      nextTick(() => {
        renderSubmenu(index)
      })
    }
  } else {
    showDropdownMenu.value = false
  }
}

const setMenuType = () => {
  if(window.innerWidth > 1279) {
    menus.value = rawMenus
  } else {
    let tabletMenus = [ ...rawMenus ]
    tabletMenus.sort((a, b) => {
      return b.children.length - a.children.length
    })

    menus.value = [
      ...tabletMenus.slice(0, 3),
      {
        id: 'more',
        url: '',
        title: 'Еще',
        children: [ ...tabletMenus.slice(3) ]
      }
    ]
  }
  prebuildDropdowns()
}

const hideMenu = () => {
  showDropdownMenu.value = false
  document.body.classList.remove('darkened')
}

</script>

<style lang="stylus">
.header
  width 100%
  background-color #fff
  position fixed
  z-index 999
  height 96px
  top 0
  left 0
  right 0
  border-bottom 2px solid #EEEFF0

  @media (min-width: 1600px)
    .rt-col
      position static!important

.inner
  margin auto
  display flex
  justify-content space-between
  align-content center
  color main-color
  height 48px

  .navigation
    font-size 18px
    display flex

    &-item
      @media screen and (max-width: 1280px)
        padding-left 20px

    a
      line-height 48px
      display flex
      color main-color
      cursor pointer

      span
        display flex
        flex-wrap: wrap
        align-content center
        justify-content space-between

      &:visited
        color #101828
      &:hover
        @media (hover: hover)
          color #868B95

  .dropdown-content
    max-width 580px
    position absolute
    top 72px
    z-index 9999
    background #fff
    box-shadow 0 0 8px rgba(28, 41, 61, 0.05), 0px 2px 4px rgba(28, 41, 61, 0.06)
    border-radius 0 0 8px 8px

    @media (min-width: 1600px)
      top 96px

    &-container
      display flex
      flex-wrap wrap
      justify-content space-between
      width 540px

      &-item
        width 260px
        height 58px
        display flex
        align-items center

        &-header
          color main-color
          &:hover
            color #868B95
        &-desc
          color main-color05
          margin-top 2px

        a
          cursor pointer
          &:hover
            color #868B95
          &:visited
            color #101828
            &:hover
              color #868B95
      &--block
        display block
        width auto
        .dropdown-content-container-item
          height auto


.logo
  span
    display block
    svg
      @media screen and (max-width mobile-upper-limit)
        width 76px

    @media screen and (max-width mobile-upper-limit)
      display block
      width 76px

.header-banner
  border-top 1px solid main-color01

  img
    max-width 176px
    object-fit: cover;
    border-radius: 12px;

  &__content
    &__description
      color main-color05
    &__link
      color main-color
      transition opacity .2s linear
      cursor pointer
      &:visited
        color main-color
      @media(hover: hover)
        &:hover
          opacity .5

.darkened
  position relative
  z-index 1
  &:after
    position absolute
    content ''
    top 0
    left 0
    right 0
    bottom 0
    background-color main-color03
</style>
