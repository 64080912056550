<template>
  <footer class="footer-content sp-t-2 md-sp-t-1-1">
    <div class="rt-container">
      <div class="rt-col">
        <div class="footer-links sp-b-2 row td-sp-b-1 md-sp-b-1-1">

          <CommonUiAppFooterDesktop :footerLinks="footerLinks" />

          <CommonUiAppFooterMobile :footerLinks="footerLinks" />

          <div class="column-contacts rt-col-3 rt-col-td-6 rt-col-md-3">
            <div class="column-contacts-container td-sp-t-1 md-sp-t-1-1 row">
              <div class="column-item-contact rt-col-percent-10 rt-col-td-2 rt-col-md-3">
                <a :href="`tel:${footerContacts.title}`" class="phone font-h3 color-main085">{{ footerContacts.title }}</a>
                <p class="contact-desc font-t-l sp-b-2 td-sp-b-none md-sp-b-1">Контактный телефон</p>
              </div>
              <div class="column-item-contact rt-col-percent-10 rt-col-td-2 rt-col-md-3">
                <div class="column-item-socials-link">
                  <a
                    v-for="link of footerContacts.children.data" :key="link.id"
                    :href="link.attributes.url"
                    class="sp-r-0-4 d-inline-flex footer-social-link md-sp-r-0-3"
                    v-html="icons[link.attributes.title]"
                  ></a>
                </div>
                <p class="contact-desc font-t-l sp-t-0-2">Социальные сети</p>
              </div>
            </div>
          </div>
        </div>
        <div class="footer-bottom font-t-m sp-t-2 sp-b-3 md-sp-v-1-1">
          <a :href="policy.url">{{ policy.title }}</a>
          <p>{{ register.title }}</p>
        </div>
      </div>
    </div>
  </footer>
</template>

<script setup>
import icons from '../assets/icons.ts'

const props = defineProps({
  menu: Object
})

const footerLinks = toRaw(props.menu.items).data.slice(0, 3)
const footerContacts = toRaw(props.menu.items).data.slice(3)[0]?.attributes
const footerLegals = toRaw(props.menu.items).data.slice(4)[0]?.attributes.children.data.map(item => {
  return {
    title: item.attributes.title,
    url: item.attributes.url
  }
})

const policy = footerLegals.find(item => item.url.length)
const register = footerLegals.find(item => !item.url.length)

</script>

<style lang="stylus">
.footer-content
  position relative
  background #F8F8F8
  width 100%
  color #343B48
  border-top 2px solid #EEEFF0

.footer-links
  display flex

  @media screen and (max-width: tablet-upper-limit)
    flex-wrap wrap

.column
  list-style-type none

  &-contacts

    &-container
      @media (max-width: tablet-upper-limit)
        position relative
        &:before
          position absolute
          content ''
          top 0
          left -10px
          right -10px
          height 2px
          background-color main-color007
      .phone
        @media screen and (max-width: tablet-upper-limit)
          font-size 20px

  &-header
    color #868B95

  a
    color #343B48

    &:hover
      color #868B95
    &:visited
      color #343B48

.contact-desc
  color #868B95

.footer-bottom
  color #868B95
  position relative
  a
    color #868B95
  @media (max-width mobile-upper-limit)
    font-size 12px
    line-height 16px
  &:before
    position absolute
    content ''
    height 2px
    background-color main-color007
    top 0
    left calc((100vw - 100% - var(--scrollbarWidth)) / -2)
    right calc((100vw - 100% - var(--scrollbarWidth)) / -2)
    @media (max-width tablet-upper-limit)
      left -20px
      right -20px

.footer-social-link
  width 24px
  height 24px
  box-sizing content-box
  align-items center
  justify-content center
  transition opacity .2s linear
  @media(hover: hover)
    &:hover
      opacity .59

</style>
