<template>
  <ul v-for="(item, idx) of footerLinks" :key="item.id" class="column rt-col-md-3 d-none td-d-none md-d-block">
    <li @click="openChildren(idx)" class="column-header font-t-l sp-b-1">
      <div class="column-header-title">
        {{ item.attributes.title }}
        <span
          v-if="item.attributes.children"
          v-html="icons.chevron"
          :class="[ activeSubmenus.includes(idx) ? 'chevron-active' : 'chevron']"
        ></span>
      </div>
      <div class="column-dropdown" :class="[ activeSubmenus.includes(idx) ? 'column-dropdown-active' : '' ]" :style="{ 'max-height': activeSubmenusHeights[idx] }">
        <li class="column-item sp-b-1 font-h-l" v-for="(child, idx) of item.attributes.children.data" :key="idx">
          <NuxtLink :href="child.attributes.url" :target="child.attributes.target">{{child.attributes.title}}</NuxtLink>
        </li>
      </div>
    </li>
  </ul>
</template>

<script setup>
import icons from '~/assets/icons';

const props = defineProps({
  footerLinks: Array
})

const activeSubmenus = ref([])
const activeSubmenusHeights = ref({})

const openChildren = (idx) => {
  if (!activeSubmenus.value.includes(idx)) {
    activeSubmenus.value.push(idx)
    activeSubmenusHeights.value[idx] = props.footerLinks[idx].attributes.children.data.length * 40 + 'px'
  } else {
    activeSubmenus.value.splice(activeSubmenus.value.indexOf(idx), 1)
    activeSubmenusHeights.value[idx] = '0px'
  }
}

</script>

<style lang="stylus" scoped>
.column
  list-style-type none
  @media (max-width mobile-upper-limit)
    &:last-of-type
      margin-bottom 4px

  &-header
    color main-color
    
    &-title
      display flex
      align-items center
      justify-content space-between
      color main-color
      font-size 14px
      line-height 16px
      font-weight 500

  &-dropdown
    max-height 0
    padding-top 0
    overflow hidden
    transition all .3s
    li
      a
        color main-color07
        font-size 14px
        line-height 20px
        font-weight 400

    &-active
      padding-top 16px
      transition all .3s

  a
    color #343B48

    &:hover
      color #868B95
    &:visited
      color #343B48

.chevron
    height 24px
    transition all .3s

    &-active
      height 24px
      transition all .3s
      transform rotateZ(-180deg)
</style>
